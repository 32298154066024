import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import '@fontsource/poppins';
import IntroBg from '../icons/intro-bubbles.svg';
import IntroBgDark from '../icons/intro-bubbles-dark.svg';
import EssentialsLogo from '../images/items/essentials-thumbnail.png';
import MegapackLogo from '../images/items/megapack-thumbnail.png';
import BagIcon from '../icons/buttons/pixfort-icon-bag-1.svg';
import EyeIcon from '../icons/buttons/pixfort-icon-eye-visibility-2.svg';
import CubeIcon from '../icons/buttons/pixfort-icon-cube-1.svg';
import WordpressIcon from '../icons/buttons/pixfort-icon-wordpress-2.svg';
import NewTabIcon from '../icons/buttons/pixfort-icon-open-new-tab-1.svg';
import Html5Icon from '../icons/buttons/pixfort-icon-html5-1.svg';
import PixfortBuilderIcon from '../icons/buttons/pixfort-icon-pixfort-builder-1.svg';
import ArrowBottomIcon from '../icons/buttons/pixfort-icon-arrow-bottom-1.svg';
import EssentialsProductImage from '../images/items/product-essentials-image.webp';
import MegapackProductImage from '../images/items/product-megapack-image.webp';

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    // const posts = data.allMarkdownRemark.nodes;

    // if (posts.length === 0) {
    //     return (
    //         <Layout location={location} title={siteTitle}>
    //             <Seo title='All posts' />
    //             <Bio />
    //             <p>No blog posts found. Add markdown posts to "content/blog" (or the directory you specified for the "gatsby-source-filesystem" plugin in gatsby-config.js).</p>
    //         </Layout>
    //     );
    // }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title='Homepage' />

            <div className=''>
                <main className='mx-auto max-w-7xl mt-24 px-4 sm:mt-24 sm:px-6 md:mt-36 lg:px-8'>
                    <div className='text-center relative z-10'>
                        <h1 className='max-w-xl inline-block text-4xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl'>
                        <span className='block xl:inline'>Take your website to the 
                                {/* <span className='relative inline-flex ml-3 text-transparent bg-clip-text bg-gradient-to-r from-[#37FFBB]/70 dark:from-[#fff]/60 via-[#0389FF]/80 dark:via-white to-[#FF5FB2] dark:to-[#fff]/40 '> next-level. */}
                                <span className='relative inline-block ml-3 text-transparent bg-clip-text bg-gradient-to-r from-[#52FFCF] dark:from-[#fff]/60 via-[#5592FF] dark:via-white to-[#FF71C4] dark:to-[#fff]/40 '> next-level.
                            <svg height='1' width='142' viewBox='0 0 120 35' preserveAspectRatio="none" className='title-underline overflow-visible inline-block h-7 ml-3 absolute -left-4 -bottom-10 text-gray-200 dark:text-white/20'>
                                {/* <text className="" x="0" y="0" dy="24" fill="currentColor">next-level.</text> */}
                                <path className="stroke" fill="none" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="30" d="M16.7 20.2c76.5 4.4 153.6-9.7 229.8-4.1 5.4.4 12.4 2.1 11.7 5.6-67.3 1.7-134.5 5.5-201.2 11.5l87.7-.9c35.2-.4 70.8-.7 104.9 4.6"></path>
                                </svg>
                                </span>
                            </span>
                        </h1>
                        <div>
                            <p className='inline-block mt-6 text-base font-light text-gray-400 sm:mx-auto sm:mt-5 sm:text-lg md:mt-8 md:text-xl lg:mx-0'>We develop powerful digital products to help create awesome websites.</p>
                        </div>
                        <div className='mt-6 sm:flex sm:justify-center'>
                            <div className=''>
                                <a href='#products' className='inline-flex items-center justify-center rounded-md border border-black/10 dark:border-white/20 bg-primary px-8 py-3 text-base font-semibold text-white hover:bg-primary/90 md:py-3 md:px-5 transition ease-in-out hover:-translate-y-1 duration-200 shadow-lg shadow-primary/25  focus:outline-none'>
                                    <CubeIcon className='inline-block mr-1.5'/>
                                    View Products
                                </a>
                            </div>
                        </div>
                        <div className='flex justify-center mt-8 lg:mt-0'>
                            <a href='#products' className='scroll-smooth'>
                            <div className='animate-bounce  backdrop-blur-sm bg-white dark:bg-black/30 p-2 ring-1 ring-slate-900/5 dark:ring-black/40 shadow-xl dark:shadow-black/50 rounded-full flex items-center justify-center text-gray-700 dark:text-white'>
                                <ArrowBottomIcon className='inline-block stroke-[2px]' width='30' height='30'/>
                            </div>
                            </a>
                        </div>
                        
                    </div>
                    <div id='products' className='relative flex flex-col justify-items-center z-0'>
                        <IntroBg className='absolute w-screen transform top-[-400px] md:top-[-300px] lg:top-[-260px] pointer-events-none -z-10 px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 block dark:hidden'/>
                        <IntroBgDark className='absolute w-screen transform top-[-400px] sm:top-[-350px] md:top-[-200px] lg:top-[-150px] pointer-events-none -z-10 px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 dark:block hidden'/>

                        {/* Essentials */}

                        <div className='max-w-4xl mx-auto flex lg:flex-row flex-col items-end shadow-2xl bg-white/70 dark:bg-black/50 dark:backdrop-brightness-50 bg-opacity-10 backdrop-blur-3xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 justify-self-center mt-12 md:mt-24 lg:mt-28 w-full relative z-10  rounded-xl top-0 transition ease-in-out hover:-translate-y-1 duration-200'>
                            <div className='mx-auto lg:basis-5/12 pt-8 lg:py-14 lg:pl-14 justify-center text-center lg:text-left  px-2 sm:px-0'>
                                <div className='justify-center'>
                                    <img className='inline-block' height='90' width='90' src={EssentialsLogo} />
                                </div>
                                <div className='inline-flex items-center border border-gray-200 dark:border-white/20 mb-4 mt-5 pl-2 pr-3 py-1 rounded-full font-semibold text-sm dark:text-white'>
                                    <WordpressIcon className='inline-block mr-1 text-primary dark:text-white' width='22' /> 
                                    WordPress
                                </div>
                                <h2 className='text-3xl dark:text-white font-semibold tracking-tight'>Essentials theme</h2>
                                <p className='inline-block max-w-xs text-base font-light text-gray-400 dark:text-white/50 sm:mx-auto sm:text-lg mt-2 md:text-lg lg:mx-0'>The most advanced and intutive WordPress theme ever.</p>
                                <div className='mt-4 flex flex-col sm:flex-row px-8 md:px-0 text-center md:text-left justify-center lg:justify-start'>
                                    <a href='https://essentials.pixfort.com/' target='_blank' className='text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 py-3 px-5 sm:mr-2 mb-2 bg-white dark:bg-white/20 text-sm font-semibold rounded-md shadow-lg shadow-gray-600/10 focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'>
                                        <EyeIcon className='inline-block mr-1'/>
                                        View Demo
                                    </a>
                                    <a href='https://1.envato.market/Essentials' target='_blank' className='py-3 px-5 bg-primary text-white hover:text-white/70 text-sm mb-2 font-semibold rounded-md shadow-lg shadow-primary/10 focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/10 dark:border-white/10'>
                                        <BagIcon className='inline-block mr-1'/>
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                            <div className='lg:basis-7/12 -z-10'>
                                
                                
                                <img className='lg:absolute right-0 bottom-0 lg:max-w-xl pointer-events-none' width='576' height="411" src={EssentialsProductImage} />
                            </div>
                        </div>

                        {/* MEGAPACK */}

                        <div className='max-w-4xl mx-auto flex lg:flex-row flex-col items-center shadow-2xl bg-white/70 dark:bg-black/40 dark:backdrop-brightness-50 bg-opacity-10 backdrop-blur-3xl shadow-slate-300/50 dark:shadow-white/5 border border-gray-200 dark:border-white/10 justify-self-center mt-[50px] w-full relative z-10  rounded-xl top-0 transition ease-in-out hover:-translate-y-1 duration-200'>
                            <div className='mx-auto lg:basis-6/12 pt-8 lg:py-14 lg:pl-14 justify-center text-center lg:text-left  px-2 sm:px-0'>
                                <div className='justify-center'><img className='inline-block' height='90' width='90' src={MegapackLogo} /></div>
                                <div className='inline-flex items-center border border-gray-200 dark:border-white/20 mb-4 mt-5 pl-2 pr-3 py-1 rounded-full font-semibold text-sm dark:text-white mr-2'>
                                    <Html5Icon className='inline-block mr-1 text-orange-500 dark:text-white' width='20'/> 
                                    HTML
                                </div>
                                <div className='inline-flex items-center border border-gray-200 dark:border-white/20 mb-4 mt-5 pl-2 pr-3 py-1 rounded-full font-semibold text-sm dark:text-white'>
                                    <PixfortBuilderIcon className='inline-block text-gray-900 dark:text-white mr-1' width='20'/> 
                                    pixfort builder access
                                </div>
                                <h2 className='text-3xl dark:text-white font-semibold tracking-tight'>
                                    MEGAPACK Template<br></br>+ pixfort builder access
                                </h2>
                                <p className='inline-block max-w-xs text-base font-light text-gray-400 sm:mx-auto sm:text-lg mt-2 md:text-lg lg:mx-0'>Premium marketing HTML landing pages with pixfort builder access.</p>
                                <div className='mt-4 flex flex-col sm:flex-row px-8 md:px-0 text-center md:text-left justify-center lg:justify-start'>
                                    <a href='https://builder.pixfort.com/item/megapack' target='_blank' className='text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 py-3 px-5 sm:mr-2 mb-2 bg-white dark:bg-white/20 text-sm font-semibold rounded-md shadow-lg shadow-gray-600/10 focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'>
                                        <EyeIcon className='inline-block mr-1'/>
                                        View Demo
                                    </a>
                                    <a href='https://1.envato.market/MEGAPACK' target='_blank' className='py-3 px-5 bg-primary text-white hover:text-white/70 text-sm mb-2 font-semibold rounded-md shadow-lg shadow-primary/25 focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/10 dark:border-white/10'>
                                        <BagIcon className='inline-block mr-1'/>
                                        Buy Now
                                    </a>
                                </div>
                                <div className='mt-4'>
                                    <a href='https://builder.pixfort.com/' target='_blank' className='text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 pb-3 px-3 sm:mr-2 mb-2 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200'>
                                            <NewTabIcon className='inline-block mr-1'/>
                                            Open pixfort builder website
                                    </a>
                                </div>
                                

                            </div>
                            <div className='lg:basis-6/12'>
                                <img className='lg:absolute lg:right-0 lg:bottom-0 lg:top-0 lg:mt-auto lg:mb-auto lg:max-w-lg pointer-events-none' width='512' height="438" src={MegapackProductImage} />
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </Layout>
    );
};

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
    }
`;
